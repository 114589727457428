export const getSystemMessage = (info, body) => {
  const { type, systemEventAccount, account } = info;

  switch (type) {
    case 1:
      return `${account?.first_name} ${account?.last_name} has invited ${systemEventAccount?.first_name} ${systemEventAccount?.last_name} to the chat.`;
    case 2:
      return `${account?.first_name} ${account?.last_name} has removed ${systemEventAccount?.first_name} ${systemEventAccount?.last_name} from the chat.`;
    case 3:
      return `Chat name has changed`
    default:
      return body;
  }
};

import React, { createContext, useState, useEffect, useCallback, useMemo } from "react";
import actionCable from "actioncable";
import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ChatIcon from "@untitled-ui/icons-react/build/esm/MessageChatSquare";

import { useAuth } from "src/hooks/use-auth";
import { toast } from "react-hot-toast";
import { paths } from "src/paths";
import { useRouter } from "src/hooks/use-router";
import { customersApi } from "src/api/customers";
import { settingsApi } from "src/api/settings";
import { useNotifications } from "src/hooks/use-notifications";
import { getBaseSocketUrl } from "src/config";
import { useCallProviders } from "src/hooks/call-system/useCallProviders";

const NAME_TO_ID = {
  Twilio: 1,
  Coperato: 2,
  Voiso: 3,
  "Cyprus BPX": 4,
  Squaretalk: 5,
  Commpeak: 6,
  MMDsmart: 7,
  "Prime Voip": 8,
};

const CallProviderContext = createContext();

const CableApp = {};
CableApp.cable = actionCable.createConsumer(
  `${getBaseSocketUrl()}/cable?token=${localStorage.getItem("token")}`
);

const useInboundCalling = (twilioEnabled) => {
  const { user } = useAuth();
  const { player } = useNotifications();

  const [calling, setCalling] = useState(false);
  const [inboundCaller, setInboundCaller] = useState(null);
  const [channel, setChannel] = useState(null);

  const handleInboundCallingConnect = useCallback(() => {
    const inboundCallChannel = CableApp.cable.subscriptions.create(
      {
        channel: "ConversationCallingChannel",
        account_id: user?.id,
      },
      {
        received: (message) => {
          const { ticket, conversation, calling, client } = message;
          let name = "NA";
          let avatar = null;
          if (client) {
            name = `Client ${client?.id}`;
            avatar = client?.avatar;
          } else if (ticket && ticket?.subject) {
            name = ticket.subject;
          } else {
            name = conversation?.name;
          }

          const caller = {
            name,
            avatar,
            id: client ? client?.id : "",
            type: ticket ? "ticket" : "conversation",
          };

          if (calling) {
            setCalling(true);
            setInboundCaller(caller);
            if (player && twilioEnabled) player.play();

            initConversationCallingStatusSocket(conversation);
          }
        },
      }
    );

    setChannel(inboundCallChannel);
  }, [user, twilioEnabled]);

  const initConversationCallingStatusSocket = useCallback((conversation = {}) => {
    const channelName = "ConversationCallingStatusChannel";
    const cable = actionCable.createConsumer(`${getBaseSocketUrl()}/cable?token=${conversation?.token}`);
    cable.subscriptions?.create(
      {
        channel: channelName,
        account_id: conversation?.id,
      },
      {
        received: (message) => {
          if (!message?.account_id || message?.account_id != user?.id) {
            if (player) player.pause();
            cable.disconnect();
          }
        }
      }
    )
  }, [user]);

  useEffect(() => {
    if (user) {
      handleInboundCallingConnect();
    }

    return () => {
      channel?.unsubscribe();
    };
  }, [user, twilioEnabled]);

  return {
    calling,
    inboundCaller,
    setCalling,
    player,
  };
};

const CallProvider = ({ children }) => {
  const router = useRouter();
  const { user } = useAuth();
  const providers = useCallProviders();

  const twilioEnabled = useMemo(() => {
    if (providers?.length > 0) {
      const twilioProvider = providers?.find(p => p?.name === "Twilio");
      return twilioProvider?.enabled;
    }
    return false;
  }, [providers]);

  const [openClientCall, setOpenClientCall] = useState(false);
  const [caller, setCaller] = useState(null);

  const { calling, inboundCaller, setCalling, player } = useInboundCalling(twilioEnabled);

  // Stop autodial after reload
  useEffect(() => {
    const autodial = localStorage.getItem("autodial");
    if (autodial) {
      localStorage.removeItem("autodial");
      localStorage.removeItem("autodial_call_provider");
      localStorage.removeItem("autodial_label");
      localStorage.removeItem("autodial_max_try");
      customersApi.stopAutodial();
    }
  }, []);

  useEffect(() => {
    if (user?.id) handleCallStatusSocketConnect();
  }, [user]);

  const handleCallStatusSocketConnect = useCallback(() => {
    CableApp.cable.subscriptions.create(
      {
        channel: "CallStatusChannel",
        account_id: user?.id,
      },
      {
        received: (message) => {
          handleCallStatusMessage(message);
        },
      }
    );
  }, [user, twilioEnabled]);

  const handleCallStatusMessage = useCallback((message) => {
    const autodial = localStorage.getItem("autodial")
      ? JSON.parse(localStorage.getItem("autodial"))
      : "";

    if (
      autodial &&
      message?.calling?.status === "END" &&
      message?.calling?.type === "outgoing"
    ) {
      handleAutodial();
    }

    if (message?.client_id && message?.calling?.type !== "outgoing" && user?.my_ringing_tone) {
      if (player && twilioEnabled) player.play();
      setOpenClientCall(true);
      setCaller({
        id: message?.client_id,
        name: message?.client_name,
      });
    }
  }, [player, user, twilioEnabled]);

  const handleAutodial = useCallback(async () => {
    const labelId = localStorage.getItem("autodial_label");
    const maxTry = localStorage.getItem("autodial_max_try");
    const request = {};

    request.client_label_id = labelId;
    if (maxTry) request.max_try = maxTry;

    const response = await customersApi.startAutodial(request);
    if (!response.ticket) {
      await customersApi.stopAutodial();
      localStorage.removeItem("autodial");
      localStorage.removeItem("autodial_label");
      localStorage.removeItem("autodial_max_try");
      toast("There are no tickets for this label. Please try another!");
      return;
    }

    const customerPhoneNumber = response?.client_phone_numbers?.[0];

    if (!customerPhoneNumber) {
      toast("Customer does not have a phone number!");
      handleAutodial();
      return;
    }

    handleMakeProviderCall(customerPhoneNumber.id);
  }, [customersApi]);

  const handleMakeProviderCall = useCallback(async (phone) => {
    const callProvider = localStorage.getItem("autodial_call_provider");
    await settingsApi.callRequest({
      call_system: NAME_TO_ID[callProvider],
      phone_number: phone,
    });
    toast(`${callProvider} call has started!`);
  }, []);

  const handleOpenClientChat = useCallback(() => {
    if (caller) {
      if (player) player.pause();
      router.push(paths.dashboard.chat + `?customer=${caller?.id}`);
    } else if (inboundCaller) {
      if (player) player.pause();
      router.push(paths.dashboard.chat + `?customer=${inboundCaller?.id}`);
      setCalling(false);
    }
  }, [router, caller, inboundCaller]);

  const handleCloseCalling = () => {
    setCalling(false);
    if (player) player.pause();
  }

  const handleCloseClientCalling = () => {
    setOpenClientCall(false);
    if (player) player.pause();
  }

  return (
    <CallProviderContext.Provider value={{}}>
      {children}

      <Snackbar
        open={calling}
        onClose={handleCloseCalling}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert severity="info" onClose={handleCloseCalling}>
          <AlertTitle>Incoming inbound call</AlertTitle>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography variant="h7">
              {inboundCaller?.name} is calling!
            </Typography>
            <Tooltip title="Open chat">
              <IconButton onClick={handleOpenClientChat}>
                <SvgIcon fontSize="large">
                  <ChatIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        </Alert>
      </Snackbar>

      <Snackbar
        open={openClientCall}
        onClose={handleCloseClientCalling}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert severity="info" onClose={handleCloseClientCalling}>
          <AlertTitle>Incoming call</AlertTitle>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography variant="h7">{caller?.name} is calling!</Typography>
            <Tooltip title="Open chat">
              <IconButton onClick={handleOpenClientChat}>
                <SvgIcon fontSize="large">
                  <ChatIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        </Alert>
      </Snackbar>
    </CallProviderContext.Provider>
  );
};

export { CallProviderContext, CallProvider };

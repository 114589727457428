import PropTypes from 'prop-types';
import { format } from 'date-fns';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Typography from '@mui/material/Typography';

import { Scrollbar } from 'src/components/scrollbar';
import { paths } from 'src/paths';

const renderContent = (notification) => {
  const createdAt = format(new Date(notification?.created_at), 'MMM dd, h:mm a');
  return (
    <ListItemText
      primary={(
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          <Link
            href={`${paths.dashboard.customers.index}/${notification?.client_id}`}
            underline="always"
            variant="body2"
          >
            {notification.client_name}
          </Link>
          <Typography
            sx={{ ml: 2, color: notification?.seen ? "text.disabled" : "text.primary" }}
            variant="body2"
          >
            {notification.message}
          </Typography>

        </Box>
      )}
      secondary={(
        <Typography
          color="text.secondary"
          variant="caption"
        >
          {createdAt}
        </Typography>
      )}
      sx={{ my: 0 }}
    />
  );
};

export const NotificationsPopover = (props) => {
  const {
    anchorEl,
    notifications,
    onClose,
    open = false,
    unread,
    setReadNotification,
    ...other
  } = props;

  const isEmpty = notifications.length === 0;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography
          color="inherit"
          variant="h6"
        >
          Notifications
        </Typography>
        <IconButton
          disabled={unread < 1}
          onClick={() => setReadNotification(notifications?.filter(item => item?.seen == false)?.map((item) => item?.id))}
          size="small"
          color="inherit"
        >
          <Tooltip title="Mark all as read">
            <SvgIcon>
              <ChecklistIcon />
            </SvgIcon>
          </Tooltip>
        </IconButton>
      </Stack>
      {isEmpty
        ? (
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2">
              There are no notifications
            </Typography>
          </Box>
        )
        : (
          <Scrollbar sx={{ maxHeight: 400 }}>
            <List disablePadding>
              {notifications.map((notification) => (
                <ListItem
                  divider
                  key={notification.id}
                  sx={{
                    alignItems: 'flex-start',
                    '&:hover': {
                      backgroundColor: 'action.hover'
                    },
                    '& .MuiListItemSecondaryAction-root': {
                      top: '24%'
                    }
                  }}
                >
                  <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: 1 }}>
                    {renderContent(notification)}
                    {notification?.seen ? null
                      : (
                        <Tooltip title="Mark as read">
                          <IconButton
                            edge="end"
                            onClick={() => setReadNotification([notification.id])}
                            size="small"
                            color="primary"
                          >
                            <SvgIcon>
                              <XIcon />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Scrollbar>
        )}
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  unread: PropTypes.number,
  setReadNotification: PropTypes.func,
  open: PropTypes.bool
};

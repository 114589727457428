import { useCallback, useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { Stack } from "@mui/system";
import { Button, TextField, IconButton } from "@mui/material";
import { Icon } from "@iconify/react";

import { SeverityPill } from "src/components/severity-pill";
import Iconify from 'src/components/iconify';

export const SelectMenu = ({
  list,
  control,
  name,
  label,
  isSearch = false,
  isApiSearch = false,
  apiSearch = "",
  setApiSearch = () => { },
  isLabel = false,
  editLabel = "Edit brand status",
  openModal = () => { },
  clearable = false,
  access = {},
  isDesk = false,
  disabled = false,
  selfDesks = [],
  isIcon = false,
  ...other
}) => {
  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 1,
      },
    },
  };

  const [search, setSearch] = useState("");
  const [values, setValues] = useState([]);

  const handleSearch = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setSearch(event.target.value);
  }, []);

  useEffect(() => {
    setValues(list);
  }, [list]);

  useEffect(() => {
    const filteredValues = values?.filter((val) =>
      val?.label?.toLowerCase()?.includes(search?.toLowerCase())
    );
    if (search) {
      setValues(filteredValues);
    } else {
      setValues(list);
    }
  }, [search, list]);

  return (
    <Box
      sx={{ width: 1, display: "flex", gap: 1, flexDirection: "column" }}
      {...other}
    >
      {label && <Typography px={1}>{label}</Typography>}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Select
              fullWidth
              disabled={disabled}
              error={!!error?.message}
              value={
                value instanceof Array && value?.length === 1
                  ? value[0]
                  : value instanceof Array && value?.length > 1
                    ? 0
                    : isDesk && access?.acc_v_client_desk
                      ? value + ""
                      : isDesk &&
                        (access?.acc_v_client_self_desk === undefined ||
                          access?.acc_v_client_self_desk) &&
                        selfDesks?.includes(value)
                        ? value
                        : !isDesk
                          ? value + ""
                          : isDesk
                            ? "hidden"
                            : "" ?? ""
              }
              onChange={(event) => onChange(event?.target?.value)}
              MenuProps={MenuProps}
              endAdornment={
                clearable && value ? (
                  <IconButton sx={{ mr: 1 }} onClick={() => onChange("")}>
                    <Iconify icon="iconoir:xmark" />
                  </IconButton>
                ) : null
              }
            >
              {(isSearch || isApiSearch) ? (
                <Stack direction="row" sx={{ px: 2, py: 1 }} value="search">
                  <TextField
                    size="small"
                    sx={{ width: 1 }}
                    type="search"
                    placeholder={"Search"}
                    onChange={isApiSearch ? setApiSearch : handleSearch}
                    hiddenLabel
                    value={isApiSearch ? apiSearch : search}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Stack>
              ) : null}
              {isDesk ? (
                <MenuItem value={"hidden"} sx={{ display: "none" }}>
                  **********
                </MenuItem>
              ) : null}
              {values?.map((item) => (
                <MenuItem
                  key={item?.value}
                  value={item?.value}
                  sx={{ display: item?.isHidden ? "none" : "" }}
                >
                  {isIcon ? (
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Icon icon={item.label} width={24} />
                      <Typography>{item?.title}</Typography>
                    </Stack>
                  ) : item.label}

                  {item?.default && (
                    <SeverityPill color="info" sx={{ ml: 2, fontSize: 10 }}>
                      Default
                    </SeverityPill>
                  )}
                </MenuItem>
              ))}
              {!!value &&
                (value instanceof Number || value instanceof String) &&
                !values?.map((v) => v?.value)?.includes(value) && (
                  <MenuItem value={value}>{value}</MenuItem>
                )}
              {isLabel ? (
                <Stack direction="row" sx={{ px: 2 }} justifyContent="center">
                  <Button sx={{ px: 0 }} onClick={() => openModal()}>
                    {editLabel}
                  </Button>
                </Stack>
              ) : null}
            </Select>
            {!!error?.message && <FormHelperText sx={{ px: 2 }} error={!!error?.message}>
              {error?.message}
            </FormHelperText>}
          </>
        )}
      />
    </Box>
  );
};
